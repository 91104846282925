import { Injectable } from '@angular/core';
import {
  AccountService,
  PricemonitorUserInfo,
} from '@Patagona/pricemonitor-internal-typescript-angular-13';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PricemonitorAuthService {
  constructor(private readonly accountService: AccountService) {}

  public getUserDetails(): Observable<PricemonitorUserInfo> {
    return this.accountService.userInfo();
  }
}
