import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
  PricemonitorUserContract,
  PricemonitorUserInfo,
} from '@Patagona/pricemonitor-internal-typescript-angular-13';

import {
  catchError,
  EMPTY,
  filter,
  map,
  mergeMap,
  Observable,
  of,
  take,
  withLatestFrom,
} from 'rxjs';

import { AppInsightsService } from '@app/tracing/application-insights/app-insights.service';

import { PricemonitorAuthService } from '@services/authentication/pricemonitor-auth.service';
import { GainsightService } from '@services/gainsight/gainsight.service';

import { AppState } from '@store/app.state';
import { authAction, companiesAction } from '@store/user';

import { selectActiveCompany, selectActiveContract } from '../preferences/preferences.selectors';

import { setGainsightUserInfoAction, userDetailsAction } from './details.actions';
import { selectUserDetails } from './details.selectors';

@Injectable()
export class UserDetailsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly pricemonitorAuthService: PricemonitorAuthService,
    private readonly gainsightService: GainsightService,
    private readonly appInsightsService: AppInsightsService
  ) {}

  public readonly setUserDetails$ = createEffect(() => this.setUserDetails());

  public readonly setGainsightUserInfo$ = createEffect(() => this.setGainsightUserInfo());

  private sortCompanyContract(contracts: PricemonitorUserContract[]) {
    return contracts.sort((a, b) => a.name.localeCompare(b.name));
  }

  private setUserDetails(): Observable<Action> {
    return this.actions$.pipe(
      ofType(userDetailsAction.init),
      mergeMap(() => this._setUserDetails())
    );
  }

  private _setUserDetails(): Observable<Action> {
    return this.pricemonitorAuthService.getUserDetails().pipe(
      take(1),
      filter(Boolean),
      map((data: PricemonitorUserInfo) => {
        const { companies, ...userDetails } = data;
        const sortedCompanies = companies.map((company) => ({
          ...company,
          contracts: this.sortCompanyContract(company.contracts),
        }));

        this.store.dispatch(companiesAction.init({ companies: sortedCompanies }));
        return userDetailsAction.success({ userDetails });
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        this.store.dispatch(userDetailsAction.failed({ error }));
        return EMPTY;
      })
    );
  }

  private setGainsightUserInfo(): Observable<Action> {
    return this.actions$.pipe(
      ofType(authAction.success),
      withLatestFrom(
        this.store.select(selectUserDetails),
        this.store.select(selectActiveCompany),
        this.store.select(selectActiveContract)
      ),
      mergeMap(([_, userInfo, userCompany, contract]) => {
        if (userInfo && contract && userCompany) {
          this.gainsightService.identify(userInfo, userCompany, contract);
          return of(setGainsightUserInfoAction.success());
        } else {
          return of(setGainsightUserInfoAction.failed());
        }
      })
    );
  }
}
