import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';

import { environment } from '@environments/environment';

export const TOKEN_EXPIRES_IN = 86400 * 1000; // js date needs miliseconds, thus * 1000
const { domain, clientId, audience, redirectUri } =
  environment.features.pricemonitorSingleSignOn.properties;

export const authenticationConfig = {
  domain,
  clientId,
  authorizationParams: {
    audience,
    redirect_uri: redirectUri,
  },
};

export const authenticationProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthHttpInterceptor,
    multi: true,
  },
];
