import { AuthService } from '@auth0/auth0-angular';
import { Configuration } from '@Patagona/pricemonitor-internal-typescript-angular-13';

import { environment } from '@environments/environment';

const basePath = environment.pricemonitorApiBaseUrl;

export const PMConfigFactory = (authService: AuthService): Configuration => {
  const config = new Configuration({
    basePath,
  });

  authService.getAccessTokenSilently().subscribe(
    (res: string) =>
      (config.credentials = {
        Bearer: res,
      })
  );
  return config;
};
