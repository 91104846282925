import { DOCUMENT } from '@angular/common';
import { ErrorHandler, inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ITelemetryInitializerHandler } from '@microsoft/applicationinsights-core-js';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppInsightsService {
  private readonly DOCUMENT = inject(DOCUMENT);

  private readonly window = this.DOCUMENT.defaultView;

  private readonly angularPlugin = new AngularPlugin();

  private readonly appInsights: ApplicationInsights = new ApplicationInsights({
    config: {
      connectionString: environment.features.applicationInsights.connectionString,
      enableDebug: true,
      extensions: [this.angularPlugin],
      extensionConfig: {
        dependencyTrackingExcludedDomains: ['https://esp.aptrinsic.com'],
        [this.angularPlugin.identifier]: {
          router: this.router,
          errorServices: [new ErrorHandler()],
        },
      },
    },
  });

  telemetryInitializer: ITelemetryInitializerHandler | undefined;

  constructor(private readonly router: Router) {
    this.appInsights.loadAppInsights();
    this.loadTelemetryInitializer();
  }

  loadTelemetryInitializer() {
    if (this.telemetryInitializer) {
      this.telemetryInitializer.remove();
    }
    const windowHeight = this.window?.screen.height ?? window.screen.height;
    const windowWidth = this.window?.screen.width ?? window.screen.width;
    this.telemetryInitializer = this.appInsights.addTelemetryInitializer(
      (envelope: ITelemetryItem) => {
        envelope.data = envelope.data ?? {};
        envelope.data['environment'] = environment.features.applicationInsights.envName;
        envelope.data['tabSessionId'] = sessionStorage.getItem('TabSessionId');
        envelope.data['screenSize'] = `${windowWidth}x${windowHeight}`;
      }
    );
    if (this.window) {
      this.window.addEventListener('resize', this.logResizeEvent.bind(this));
    }
    this.appInsights.trackPageView();
  }

  logEvent(name: string, properties: { [key: string]: any }) {
    this.appInsights.trackEvent({
      name,
      properties,
    });
  }

  logResizeEvent() {
    this.appInsights.trackEvent({
      name: 'ScreenSizeChanged',
      properties: {
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
      },
    });
  }
}
