import { Injectable } from '@angular/core';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarConfig as MatSnackBarConfig,
} from '@angular/material/legacy-snack-bar';

import { MutationNotificationComponent } from '../mutation-notification/mutation-notification.component';

import { SnackbarComponent } from './snackbar.component';
import { SnackbarConfig } from './snackbar.interface';

// Available durations for showing snackbar
export enum SnackbarDuration {
  FIVE_SECONDS = 5000,
  DEFAULT = 3500,
}

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private readonly snackBar: MatSnackBar) {}

  public default(opts: SnackbarConfig): void {
    return this.notify({ ...opts, type: 'default', className: 'default-dialog' });
  }

  public success(opts: SnackbarConfig): void {
    return this.notify({ ...opts, type: 'success', className: 'success-dialog' });
  }

  public error(opts: SnackbarConfig): void {
    return this.notify({ ...opts, type: 'error', className: 'error-dialog' });
  }

  public warning(opts: SnackbarConfig): void {
    return this.notify({ ...opts, type: 'warning', className: 'warning-dialog' });
  }

  public info(opts: SnackbarConfig): void {
    return this.notify({ ...opts, type: 'info', className: 'info-dialog' });
  }

  public notifyChanges(): void {
    this.snackBar.openFromComponent(MutationNotificationComponent, {
      panelClass: 'default-dialog',
      verticalPosition: 'top',
      horizontalPosition: 'center',
    });
  }

  private notify(opts: SnackbarConfig): void {
    const options: MatSnackBarConfig = {
      duration: opts.duration || SnackbarDuration.DEFAULT,
      panelClass: opts.className || 'default-dialog',
    };
    if (this.snackBar._openedSnackBarRef) {
      return;
    }

    if (opts.hasHtml) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        ...options,
        data: opts.message,
      });
    } else {
      this.snackBar.open(opts.message, 'Close', options);
    }
  }
}
