import { PricemonitorUserCompany } from '@Patagona/pricemonitor-internal-typescript-angular-13';

import { ICompany } from '@app/models/interfaces/company.interface';

import { environment } from '@environments/environment';

const filterOmniaCompaniesByFeatureFlag = (features: string[]): boolean =>
  features.includes('omnia.total.market.pricing');

const filterCompaniesByEnvironment = (features: string[]): boolean => {
  if (environment.envName === 'prod') {
    return !features.includes('omnia.environment.test');
  } else if (environment.envName === 'test' || environment.envName === 'local') {
    return features.includes('omnia.environment.test');
  } else {
    return false;
  }
};

export const getMappedCompanyList = (
  pricemonitorCompanies: PricemonitorUserCompany[] = []
): ICompany[] => {
  return Object.values(pricemonitorCompanies)
    .map((company) => ({
      ...company,
      contracts: company.contracts
        .filter((contract) => contract.active)
        .filter(({ features }) => filterOmniaCompaniesByFeatureFlag(features.enabled))
        .filter(({ features }) => filterCompaniesByEnvironment(features.enabled))
        .map((contract) => ({
          ...contract,
        })),
    }))
    .filter((contract) => contract.contracts.length > 0);
};
