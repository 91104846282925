import { createSelector } from '@ngrx/store';

import { ICompany } from '@app/models/interfaces/company.interface';

import { AppState } from '@store/app.state';

const selectCompaniesFeature = ({ user }: AppState) => user.companies;

export const selectCompanies = createSelector(
  selectCompaniesFeature,
  ({ ...companies }) => companies as ICompany[]
);

export const selectAllContracts = createSelector(selectCompanies, (companies) => {
  const companiesArray = Object.values(companies);
  if (!companiesArray[0].id) return;
  return Object.values(companies)
    ?.map((company) => company.contracts)
    .flat();
});
