import { combineReducers } from '@ngrx/store';

import {
  auhthenticationReducer,
  authenticationFeatureKey,
  AuthState,
  companiesFeatureKey,
  companiesReducer,
  CompaniesState,
  contractsFeatureKey,
  contractsReducer,
  ContractsState,
  preferencesFeatureKey,
  userDetailsFeatureKey,
  userDetailsReducer,
  UserDetailsState,
  userPreferenceReducer,
  UserPreferencesState,
} from '@store/user';

/**
 * @description
 * UserState encapsulates user specific information
 */
export interface UserState {
  authentication: AuthState;
  details: UserDetailsState;
  preferences: UserPreferencesState;
  companies: CompaniesState;
  contracts: ContractsState;
}

export const userReducer = combineReducers({
  [authenticationFeatureKey]: auhthenticationReducer,
  [userDetailsFeatureKey]: userDetailsReducer,
  [preferencesFeatureKey]: userPreferenceReducer,
  [companiesFeatureKey]: companiesReducer,

  [contractsFeatureKey]: contractsReducer,
});
