import { createSelector } from '@ngrx/store';

import { AppState } from '@app/store/app.state';

import { IContract } from '@models/interfaces/company.interface';

const selectUser = ({ user }: AppState) => user;

const selectPreferencesFeatureKey = createSelector(selectUser, (user) =>
  user
    ? user.preferences
    : { defaultCompany: null, activeCompany: null, isSwitchingContracts: false }
);

export const selectDefaultCompany = createSelector(
  selectPreferencesFeatureKey,
  ({ defaultCompany }) => defaultCompany
);

export const selectDefaultContract = createSelector(
  selectDefaultCompany,
  (defaultCompany) => defaultCompany?.contracts[0] as IContract
);

export const selectActiveCompany = createSelector(
  selectPreferencesFeatureKey,
  ({ activeCompany }) => activeCompany
);

export const selectActiveCompanyName = createSelector(
  selectActiveCompany,
  (activeCompany) => activeCompany?.name ?? ''
);

export const selectActiveCompanyContracts = createSelector(
  selectActiveCompany,
  (activeCompany) => activeCompany?.contracts
);

export const selectActiveContract = createSelector(
  selectPreferencesFeatureKey,
  ({ activeCompany }) => activeCompany?.contracts[0] as IContract
);

export const selectActiveContractSid = createSelector(
  selectActiveContract,
  (activeContract) => activeContract?.sid
);

export const selectIsSwitchingContract = createSelector(
  selectPreferencesFeatureKey,
  ({ isSwitchingContracts }) => isSwitchingContracts
);
