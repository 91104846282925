import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { combineLatest, map, mergeMap, Observable } from 'rxjs';

import { CompanyService } from '@services/company-mapping/company-mapping.service';

import { companiesAction } from './companies.actions';

@Injectable()
export class CompaniesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly companyService: CompanyService
  ) {}

  public readonly setCompanyData$ = createEffect(() => this.setCompanyData());

  private setCompanyData(): Observable<Action> {
    return combineLatest([this.actions$.pipe(ofType(companiesAction.init))]).pipe(
      mergeMap(([{ companies }]) => this.companyService.getAllCompanies(companies)),
      map((companies) =>
        companies.length ? companiesAction.success({ companies }) : companiesAction.failed()
      )
    );
  }
}
