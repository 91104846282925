import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { SettingsService } from '@Patagona/pricemonitor-internal-typescript-angular-13';

import { catchError, EMPTY, map, Observable, switchMap, withLatestFrom } from 'rxjs';

import { AppState } from '@store/app.state';
import { activeCompanyAction, contractSettingsAction, selectActiveContractSid } from '@store/user';

@Injectable()
export class ContractsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly settingsService: SettingsService
  ) {}

  public readonly fethcContractSettings$ = createEffect(() => this.fetchContractSettings());

  private fetchContractSettings(): Observable<Action> {
    return this.actions$.pipe(
      ofType(activeCompanyAction.success),
      withLatestFrom(this.store.select(selectActiveContractSid)),
      switchMap(([_, id]) => {
        return this.settingsService.getSettings(id).pipe(
          catchError(() => {
            this.store.dispatch(contractSettingsAction.failed());
            return EMPTY;
          })
        );
      }),
      map((settings) => contractSettingsAction.success({ settings }))
    );
  }
}
