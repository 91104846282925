import { Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';

import { INACTIVE_ACCOUNT, INVALID_ACCOUNT } from './constants/account-error.constants';
import { ROUTES } from './routes/routes.constants';

export const AppRoutes: Routes = [
  {
    path: `${ROUTES.APP.ROOT}`,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@features/app-container/app-container.routes').then(
        ({ AppContainerRoutes }) => AppContainerRoutes
      ),
  },
  {
    path: ROUTES.LOGOUT,
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('@app/features/logout/logout.component').then((m) => m.LogoutComponent),
  },
  {
    path: ROUTES.INACTIVE_ACCOUNT,
    canActivate: [AuthGuard],
    pathMatch: 'full',
    data: INACTIVE_ACCOUNT,
    loadComponent: () =>
      import('@features/account-error/account-error.component').then(
        ({ AccountErrorComponent }) => AccountErrorComponent
      ),
  },
  {
    path: ROUTES.INVALID_ACCOUNT,
    canActivate: [AuthGuard],
    pathMatch: 'full',
    data: INVALID_ACCOUNT,
    loadComponent: () =>
      import('@features/account-error/account-error.component').then(
        ({ AccountErrorComponent }) => AccountErrorComponent
      ),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: ROUTES.APP.ROOT,
  },
];
