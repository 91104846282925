import { createSelector } from '@ngrx/store';

import { AppState } from '@store/app.state';

import { AuthState } from './authentication.reducers';

const selectAuthenticationFeature = ({ user }: AppState) => user.authentication;

export const selectPmToken = createSelector(
  selectAuthenticationFeature,
  ({ token }: AuthState) => token
);
