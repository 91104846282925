import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PricemonitorUserCompany } from '@Patagona/pricemonitor-internal-typescript-angular-13';

import { Observable, of } from 'rxjs';

import { AppState } from '@store/app.state';

import { getMappedCompanyList } from '@utils/company-helpers/get-mapped-company-list';

import { ICompanyService } from './company-mapping.interface.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService implements ICompanyService {
  constructor(private readonly store: Store<AppState>) {}

  public getAllCompanies(
    companies: PricemonitorUserCompany[]
  ): Observable<PricemonitorUserCompany[]> {
    return of(getMappedCompanyList(companies));
  }
}
