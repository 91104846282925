import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import {
  catchError,
  combineLatest,
  EMPTY,
  filter,
  map,
  mergeMap,
  Observable,
  take,
  tap,
} from 'rxjs';

import { UserTokenService } from '@services/authentication/user-token.service';

import { AppState } from '@store/app.state';
import { activeCompanyAction, companiesAction, userDetailsAction } from '@store/user';

import { authAction, tokenRenewalAction, userTokenAction } from './authentication.actions';

@Injectable()
export class AuthenticationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly authService: AuthService,
    private readonly userTokenService: UserTokenService
  ) {}

  public readonly initLogin$ = createEffect(() => this.initLogin());

  public readonly handleLoginSuccess$ = createEffect(() => this.handleLoginSuccess());

  public readonly handleLoginFailure$ = createEffect(() => this.handleLoginFailure());

  public readonly initRenewToken$ = createEffect(() => this.initRenewToken(), {
    dispatch: false,
  });

  private initLogin(): Observable<Action> {
    return this.actions$.pipe(
      ofType(authAction.init),
      mergeMap(() => this._getToken()),
      map(() => userDetailsAction.init())
    );
  }

  private handleLoginSuccess(): Observable<Action> {
    return combineLatest([
      this.actions$.pipe(ofType(activeCompanyAction.success)),
      this.actions$.pipe(ofType(userDetailsAction.success)),
    ]).pipe(map(() => authAction.success()));
  }

  private handleLoginFailure(): Observable<Action> {
    return this.actions$.pipe(
      ofType(companiesAction.failed, userDetailsAction.failed, userTokenAction.failed),
      map(() => authAction.failed())
    );
  }

  private initRenewToken(): Observable<Action> {
    return this.actions$.pipe(
      ofType(tokenRenewalAction.init),
      mergeMap(() => this._getNewToken()),
      map(() => tokenRenewalAction.success())
    );
  }

  private _getToken(): Observable<string> {
    return this.authService.getAccessTokenSilently().pipe(
      tap((token) => {
        this.userTokenService.userToken = token;
        this.store.dispatch(userTokenAction.success({ token }));
      }),
      catchError((error: HttpErrorResponse) => {
        this.store.dispatch(userTokenAction.failed({ error }));

        return EMPTY;
      })
    );
  }

  private _getNewToken(): Observable<string> {
    return this.authService.getAccessTokenSilently().pipe(
      take(1),
      filter((token) => !!token),
      tap((token) => {
        this.userTokenService.userToken = token;
        this.store.dispatch(userTokenAction.success({ token }));
      }),
      catchError((error: HttpErrorResponse) => {
        this.store.dispatch(userTokenAction.failed(error));
        this.store.dispatch(tokenRenewalAction.failed());
        return EMPTY;
      })
    );
  }
}
