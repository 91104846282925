import { User } from '@auth0/auth0-angular';
import { createReducer, on } from '@ngrx/store';

import { userTokenAction } from './authentication.actions';

export interface AuthState extends User {
  token?: string;
}

export const authenticationFeatureKey = 'authentication';

export const initialAuthState: AuthState = {
  token: undefined,
};

export const auhthenticationReducer = createReducer(
  initialAuthState,
  on(userTokenAction.success, (state, { token }) => ({
    ...(state || initialAuthState),
    token,
  }))
);
